.toolBar {
  display: flex !important;
  justify-content: space-between !important;
}

.temporal {
  width: 0px !important;
  height: 0px !important;
  padding: 0px !important;
  transition: width var(--time-animation) linear !important;
}

.left-Icon {
  width: 40px !important;
  height: 40px !important;
  padding: 0px !important;
  margin-left: 0px !important;
  transition: margin-left var(--time-animation) linear !important;
}

.icon {
  color: rgba(0, 0, 0, 0.54);
}

.right-Icon {
  width: 40px !important;
  height: 40px !important;
}

.space {
  margin: 0px 15px !important;
}

.account-Icon {
  width: 50px !important;
  height: 50px !important;
  margin-left: 15px !important;
}

.leftSide {
  display: flex !important;
  flex-flow: row !important;
}

.rightSide {
  font-size: 35px !important;
  align-items: center !important;
  display: flex !important;
  flex-flow: row !important;
}

.search-bar-wrapper {
  background-color: #ffffff !important;
  height: var(--topBar-Height) !important;
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  box-shadow: rgba(145, 158, 171, 0.16) 0px 8px 16px 0px !important;
  right: 0px !important;
  display: flex !important;
  flex-flow: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 99 !important;
}

@media only screen and (max-width: 1024px) {
  .temporal {
    width: 40px !important;
    transition: width var(--time-animation) linear !important;
    height: 40px !important;
  }

  .left-Icon {
    margin-left: 22px !important;
    transition: margin-left var(--time-animation) linear !important;
  }

  .space {
    margin: 0px 0px !important;
  }

  .account-Icon {
    margin-left: 7px !important;
  }
}

@media only screen and (max-width: 350px) {
  .toolBar {
    overflow-y: scroll;
  }
}

.search-bar {
  margin: 0px 30px !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.search-bar .text-field {
  padding-left: 10px !important;
  width: 100% !important;
}
