:root {
  --topBar-Height: 70px;
  --slideBar-closed-weigth: 80px;
  --slideBar-normal-weigth: 230px;
  --slideBar-tiny-weigth: 0px;
  --slideBar-tiny-closed-weigth: 0px;
  --time-animation: 250ms;
}

.content {
  margin-left: var(--slideBar-normal-weigth) !important;
  transition: margin-left var(--time-animation) linear !important;
  margin-top: var(--topBar-Height) !important;
  width: calc(100% - var(--slideBar-normal-weigth)) !important;
}

.contentTiny {
  margin-left: var(--slideBar-normal-weigth) !important;
  transition: margin-left var(--time-animation) linear !important;
  margin-top: var(--topBar-Height) !important;
}

.contentClosed {
  margin-left: var(--slideBar-closed-weigth) !important;
  transition: margin-left var(--time-animation) linear !important;
  margin-top: var(--topBar-Height) !important;
  width: calc(100vw - var(--slideBar-closed-weigth)) !important;
}

.contentTinyClosed {
  margin-left: var(--slideBar-tiny-closed-weigth) !important;
  transition: margin-left var(--time-animation) linear !important;
  margin-top: var(--topBar-Height) !important;
  width: calc(100vw - var(--slideBar-tiny-closed-weigth)) !important;
}

.topBar {
  margin-left: var(--slideBar-normal-weigth) !important;
  transition: margin-left var(--time-animation) linear !important;
  background-color: white !important;
  position: fixed !important;
  width: auto !important;
  height: var(--topBar-Height) !important;
  top: 0px !important;
  left: 0px !important;
  box-shadow: rgba(145, 158, 171, 0.16) 8px 8px 16px 0px !important;
  right: 0px !important;
  display: flex !important;
  justify-content: center !important;
}

.menu {
  visibility: hidden !important;
  width: 0% !important;
  transition: width var(--time-animation) linear !important;
}

.slideBar {
  background-color: #0057b8 !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: var(--slideBar-normal-weigth) !important;
  transition: width var(--time-animation) linear !important;
  position: fixed !important;
  height: 100vh !important;
  display: flex !important;
  overflow-x: hidden !important;
}

.tinySlideBar {
  background-color: #0057b8 !important;
  opacity: 1 !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: var(--slideBar-normal-weigth) !important;
  transition: width var(--time-animation) linear !important;
  position: fixed !important;
  height: 100vh !important;
  display: flex !important;
  overflow-x: hidden !important;
  z-index: 9999 !important;
}

.tinySlideBarClosed {
  background-color: #0057b8 !important;
  opacity: 1 !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: var(--slideBar-tiny-weigth) !important;
  transition: width var(--time-animation) linear !important;
  position: fixed !important;
  height: 100vh !important;
  display: flex !important;
  overflow-x: hidden !important;
  z-index: 9999 !important;
}

.slideBarClosed {
  background-color: #0057b8 !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: var(--slideBar-closed-weigth) !important;
  transition: width var(--time-animation) linear !important;
  position: fixed !important;
  height: 100vh !important;
  display: flex !important;
  overflow-x: hidden !important;
}

.topBarClosed {
  margin-left: var(--slideBar-closed-weigth) !important;
  transition: margin-left var(--time-animation) linear !important;
  background-color: white !important;
  position: fixed !important;
  width: auto !important;
  height: var(--topBar-Height) !important;
  top: 0px !important;
  left: 0px !important;
  box-shadow: rgba(145, 158, 171, 0.16) 8px 8px 16px 0px !important;
  right: 0px !important;
  display: flex !important;
  justify-content: center !important;
}

@media only screen and (max-width: 1024px) {
  .slideBar {
    width: var(--slideBar-tiny-weigth) !important;
    transition: width var(--time-animation) linear !important;
  }

  .menu {
    visibility: visible !important;
    width: 100% !important;
    transition: width var(--time-animation) linear !important;
  }

  .contentTiny {
    margin-left: var(--slideBar-tiny-weigth) !important;
    transition: margin-left var(--time-animation) linear !important;
  }

  .contentClosed {
    margin-left: var(--slideBar-tiny-weigth) !important;
    transition: margin-left var(--time-animation) linear !important;
  }

  .slideBarClosed {
    width: var(--slideBar-tiny-weigth) !important;
    transition: width var(--time-animation) linear !important;
  }

  .topBarClosed {
    margin-left: var(--slideBar-tiny-weigth) !important;
    transition: margin-left var(--time-animation) linear !important;
  }
}
