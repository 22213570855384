.user-image {
  height: 105px;
  width: 105px;
}

.account-tool-popover {
  padding: 25px 20px;

  justify-content: 'center';
  align-items: 'center';
  display: 'flex';
  flex-direction: 'column';
}

.user-information {
  display: flex;
  width: 235px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.icon-wrapper {
  padding-left: 10px;
}
