.selected {
  opacity: 1 !important;
  transition: opacity var(--time-animation) linear !important;
}

.unSelected {
  opacity: 0.3 !important;
  transition: opacity var(--time-animation) linear !important;
}

.MuiListItemText-secondary {
  font-family: 'Poppins' !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  color: #ffffff !important;
  margin-left: 10px !important;
}

.MuiListItemText-primary {
  font-size: 14px !important;
  font-family: 'Poppins' !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  margin-left: 10px !important;
}

.unSelected .MuiListItemText-primary {
  font-size: 14px !important;
  font-family: 'Poppins' !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  margin-left: 10px !important;
  opacity: 0.3 !important;
  transition: opacity var(--time-animation) linear !important;
}

.MuiListItemIcon-root {
  min-width: 0 !important;
  color: #ffffff !important;
}

.unSelected .MuiListItemIcon-root {
  min-width: 0 !important;
  color: #ffffff !important;
  opacity: 0.3 !important;
  transition: opacity var(--time-animation) linear !important;
}

.icon {
  color: #ffffff !important;
}
