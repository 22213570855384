.contentSide {
  max-width: 200px !important;
  margin-left: 10px !important;
}

.logoPanel {
  height: 32px !important;
  width: 140px !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  padding-left: 0px !important;
}

.logoPanelSmall {
  width: 80px !important;
  height: 32px !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}
